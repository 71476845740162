<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Determine which of the following reactions would have a
        <b>{{ changeDirection }}</b>
        <stemble-latex content="$\Delta\text{S.}$" />
        Select all that apply.
      </p>

      <div v-for="option in options" :key="option.value" class="pl-8">
        <v-checkbox
          v-model="inputs.input1"
          dense
          :disabled="!allowEditing"
          class="d-inline-block"
          :value="option.value"
        />
        <stemble-latex :content="option.text" />
      </div>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question316',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        input1: [],
      },
    };
  },
  computed: {
    versionNumber() {
      return this.taskState.getValueBySymbol('versionNumber').content;
    },
    changeNumber() {
      return this.taskState.getValueBySymbol('changeNumber').content;
    },
    changeDirection() {
      if (this.changeNumber.value === 1) {
        return 'positive';
      } else {
        return 'negative';
      }
    },
    options() {
      if (this.versionNumber.value === 1) {
        return [
          {text: '$\\ce{2HBr(g) -> H2(g) + Br2(l)}$', value: 'a'}, // negative
          {text: '$\\ce{2SO3(g) -> 2 SO2(g) + O2(g)}$', value: 'b'}, // positive
          {text: '$\\ce{P4O10(s) + 6H2O(l) -> 4H3PO4(s)}$', value: 'c'}, // negative
        ];
      } else if (this.versionNumber.value === 2) {
        return [
          {text: '$\\ce{H2(g) + 1/2O2(g) -> H2O(l)}$', value: 'a'}, // negative
          {
            text: '$\\ce{2CH3OH(g) + 3O2(g) -> 2 CO2(g) + 4H2O(g)}$',
            value: 'b',
          }, // positive
          {
            text: '$\\ce{Fe2O3(s) + 3H2(g) -> 2Fe(s) + 3 H2O(g)}$',
            value: 'c',
          }, // positive
        ];
      } else {
        return [];
      }
    },
  },
};
</script>
<style scoped>
.v-input {
  margin-top: 0;
}
</style>
